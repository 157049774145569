<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const ITEMS = [
	{
		icon: "reason1.webp",
		title: "Play with Gold Coins for fun!",
		description: "Get 250K Gold Coins after Registration"
	},
	{
		icon: "reason2.webp",
		title: "Play with Sweeps Coins!",
		description: "Collect FREE Fortune Coins to dive into adventure"
	},
	{
		icon: "reason3.webp",
		title: "Fast prize redemption!",
		description: "Unforgettable impressions and experiences"
	},
	{
		icon: "reason4.webp",
		title: "US based company!",
		description: "FREE to Play Games"
	}
];
</script>

<template>
	<div class="why-us">
		<AText class="title" variant="taipei" as="h3" :modifiers="['uppercase', 'center']">
			<i18n-t keypath="4 reasons why {key}">
				<template #key>
					<span class="text-caracas">{{ t("fortune wheels?") }}</span>
				</template>
			</i18n-t>
		</AText>
		<div class="reasons">
			<div v-for="item in ITEMS" :key="item.title" class="reason">
				<NuxtImg
					class="icon"
					:src="`/nuxt-img/why-us/${item.icon}`"
					width="128"
					height="128"
					alt="icon"
					loading="lazy"
					format="avif"
				/>
				<div>
					<AText variant="toledo" :modifiers="['bold']" as="div">
						{{ t(item.title) }}
					</AText>
					<AText class="description text-neutral-100" variant="topeka" as="div">
						{{ t(item.description) }}
					</AText>
				</div>
			</div>
		</div>
		<AButton class="button" variant="primary" size="xl" @click="open('LazyOModalSignup')">{{
			t("Create an account")
		}}</AButton>
	</div>
</template>

<style scoped lang="scss">
.why-us {
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: 24px 0;

	@include media-breakpoint-down(lg) {
		margin: 0 16px;
	}
}
.reasons {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 12px;
	}
}

.reason {
	display: flex;
	align-items: center;
	position: relative;
	gap: 8px;
	padding: 8px 16px;
	border-radius: 12px;
	@include gradient-border(var(--gradient-g-10), 1px, var(--gray-900));
	overflow: hidden;

	@include media-breakpoint-up(md) {
		width: calc(50% - 6px);
		padding: 24px 16px;
	}

	@include media-breakpoint-up(xl) {
		width: calc(25% - 9px);
	}

	.icon {
		width: 70px;
		height: 70px;
		position: relative;

		&:deep(img) {
			width: 100%;
			height: auto;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 110px;
		height: 110px;
		background: url("/nuxt-img/why-us/circle-lines.png") no-repeat 50% 50% / cover;
		scale: 2.5;
		animation: rotate 12s linear infinite;
	}
}
.title {
	@include media-breakpoint-down(md) {
		max-width: 300px;
		margin: 0 auto;
		span {
			display: block;
		}
	}
	@include media-breakpoint-up(md) {
		font-size: 38px;
	}
}
.description {
	margin-top: 4px;
}
.button {
	max-width: 100%;
	width: 296px;
	margin: 0 auto;
	@include media-breakpoint-up(md) {
		width: 250px;
	}
}
@keyframes rotate {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
